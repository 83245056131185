import React, { useContext, useRef, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Layout, Seo, Grid, SVGWrapper } from '../components';
import { ResponsiveContext } from '../providers/responsive';
import { ThemeContext } from '../providers/themes';
import StartTinySVG from '../images/star-tiny.svg';
import '../styles/index.css';

const StyledLayout = styled(Layout)((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      height: '100vh',
      '& .navbar': {
        position: 'static',
        top: 'unset',
      },
    };
  }
  return {};
});

const ContactLink = styled.a((props) => ({
  color: props.theme.text,
}));

const ContactName = styled.h2((props) => ({
  color: props.theme.text,
}));
const JobPosition = styled.h3((props) => ({
  color: props.theme.text,
}));

const CopyRightWrapper = styled(Grid)((props) => ({
  width: '100%',
  padding: '1.625rem 0',
  alignItems: 'center',
  borderTop: `solid 1px ${props.theme.lines}`,
  marginTop: 'auto',
}));

const CopyRight = styled.div((props) => ({
  color: props.theme.text,
  maxWidth: '10.063rem',
  fontSize: '0.875rem',
}));

const BusinessCard = ({ data, location }) => {
  const intl = useIntl();
  const responsive = useContext(ResponsiveContext);
  const { isDesktopUp } = responsive;
  const currentYear = new Date().getFullYear();
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <StyledLayout location={location} title={siteTitle} responsive={responsive}>
      <Seo title="Francisco Ávila" />

      <ThemeContext.Consumer>
        {({ theme }) => (
          <div className="card-content">
            <div className="contact">
              {/* <StaticImage
                className="card-image"
                src="https://scontent-qro1-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/p750x750/241127499_569464994258649_1459109483920264191_n.jpg?_nc_ht=scontent-qro1-2.cdninstagram.com&amp;_nc_cat=109&amp;_nc_ohc=hxNbclmMsrUAX9M3Vef&amp;edm=AP_V10EBAAAA&amp;ccb=7-4&amp;oh=078224664831a1617fb1304e5d19ce63&amp;oe=613D1CA7&amp;_nc_sid=4f375e"
                width={140}
                height={140}
              /> */}

              <ContactName {...{ theme }}>Francisco Ávila</ContactName>
              <JobPosition {...{ theme }}>Co-founder</JobPosition>

              <div className="contact__links">
                <ContactLink
                  className="contact__link"
                  {...{ theme }}
                  target="_blank"
                  href="mailto:francisco@bengaru.co"
                >
                  {`${intl.formatMessage({
                    id: 'emailAbrev',
                  })} - francisco@bengaru.co`}
                </ContactLink>
                <ContactLink
                  className="contact__link"
                  {...{ theme }}
                  href="tel:+524445871030"
                >
                  {`${intl.formatMessage({
                    id: 'phoneAbrev',
                  })} - +52 444 587 1030`}
                </ContactLink>
              </div>
            </div>

            <CopyRightWrapper
              responsive={responsive}
              gridTemplateColumns="1fr auto 1fr"
              {...{ theme }}
            >
              <div></div>
              <CopyRight {...{ theme }}>
                {`© ${currentYear} Bengaru Agency. ${intl.formatMessage({
                  id: 'copyright',
                })}`}
              </CopyRight>

              <SVGWrapper color={theme.contrastColor}>
                <StartTinySVG />
              </SVGWrapper>
            </CopyRightWrapper>
          </div>
        )}
      </ThemeContext.Consumer>
    </StyledLayout>
  );
};

export default BusinessCard;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
